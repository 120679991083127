const activities = [
  { id: 1, name: 'sedentary', alias: 'sedentary' },
  { id: 2, name: 'light', alias: 'light' },
  { id: 3, name: 'moderate', alias: 'moderate' },
  {
    id: 4,
    name: 'active',
    alias: 'active',
  },
  { id: 5, name: 'extreme', alias: 'extreme' },
];

export default activities;
