import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import CheckoutForm from './form';
import axios from 'axios';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function Checkout() {
  const location = useLocation();
  const { price, data, name } = location.state;
  const [disable, setDisable] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [username, setUserName] = useState('');
  const [email, setEmail] = useState('');
  useEffect(() => {
    if (username && email) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [username, email]);

  const submitData = (e) => {
    e.preventDefault();
    console.log(username, email);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_PAYMENT_DEV}/create-checkout-session`,
        {
          username,
          email,
        }
      )
      .then((res) => {
        console.log(res.data);
        window.location.href = res.data.url;
      });
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_PAYMENT}/subscription/config`).then(
      async (response) => {
        const { publishableKey } = await response.json();
        setStripePromise(loadStripe(publishableKey));
      }
    );
  }, []);
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER_PAYMENT_DEV}/subscription/create-customer`,
      {
        method: 'POST',
        body: JSON.stringify({}),
      }
    ).then(async (response) => {
      const { clientSecret } = await response.json();
      setClientSecret(clientSecret);
    });
  }, []);

  return (
    <div>
      <Helmet
        title="Checkout"
        meta={[
          {
            name: 'description',
            content: 'Checkout page',
          },
        ]}
      />
      {/* {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm product={data} price={price} />
        </Elements>
      )} */}

      <div className="flex flex-col items-center justify-center w-full h-full text-xl mt-10">
        <div className="text-main">
          product: {name} - price: {price} Eur
        </div>
        <form>
          <input
            className="w-full h-12 px-4 mb-4 mt-10 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline"
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            name="username"
            type="text"
            placeholder="Name "
          />
          <input
            className="w-full h-12 px-4 mb-4 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            name="email"
            type="email"
            placeholder="Email"
          />
          <button
            disabled={disable}
            className={
              disable
                ? 'bg-gray-200 p-2 rounded w-full text-main'
                : 'bg-main text-white p-2 rounded w-full'
            }
            onClick={submitData}
            type="submit">
            Buy
          </button>
        </form>
      </div>
    </div>
  );
}
