import AppScreenShot from '../../img/app.webp';
import AppFeatures from '../../data/appFeatures';
import Apple from '../../img/appstore.webp';
import Google from '../../img/google.webp';
const HomeOverView = () => {
  return (
    <div class="overflow-hidden bg-main py-24 sm:py-32 rtl:mr-3">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 text-white">
          <img src={AppScreenShot} alt="Fitlinez screenshot" />
          <div class="lg:pr-8 lg:pt-4">
            <div class="lg:max-w-lg py-20">
              <h2 class="text-base  leading-7 text-white">
                Fitlinez workout Application{' '}
              </h2>
              <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Workout tracker mobile app{' '}
              </p>
              <p class="mt-6 text-lg leading-8 text-white ">
                Fitlinez is a workout tracker mobile app that helps you to
                achieve your fitness goals.{' '}
              </p>
              <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-white lg:max-w-none text-right">
                {AppFeatures.map((feature) => {
                  return (
                    <div class="relative pl-9 flex flex-row">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 ml-2">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                        />
                      </svg>

                      <dd class="inline "> {feature.details}</dd>
                    </div>
                  );
                })}
              </dl>
              <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-10">
                <div className="flex items-center h-full mx-auto">
                  <a href="https://play.google.com/store/apps/details?id=com.fitlinez.eu&hl=en">
                    <h3 className="text-center text-main mb-5">دانلود از</h3>
                    <img
                      className="object-fit "
                      src={Google}
                      alt="Fitlinez google Play Store"
                    />
                  </a>
                </div>

                <div className="flex items-center h-full mx-auto">
                  <a href="https://apps.apple.com/app/fitlinez/id6443489365">
                    <h3 className="text-center text-main mb-5">دانلود از</h3>
                    <img
                      className="object-fit"
                      src={Apple}
                      alt="Fitlinez App Store"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeOverView;
