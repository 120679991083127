import './index.css';

import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import bg from '../../img/bg.webp';

const HomeHeader = () => {
  return (
    <div className="relative">
      <div className="fixed top-0 left-0 w-full z-10"></div>
      <div className="overlay"> </div>
      <section
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          width: '100%',
        }}></section>

      {/* <video src={BgVideo} autoPlay loop muted className="w-full h-full" /> */}

      {/* <video
        autoPlay
        muted
        playsinline
        loop
        preload="auto"
        className="w-full h-full">
        <source src={BgVideo} type="video/mp4" />
        <source src={BgVideoWebm} type="video/webm" />
        <source src={BgVideoOgv} type="video/ogg" />
        Your browser does not support the video tag.
      </video> */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
        <h1 className="text-white text-7xl font-bold ml-8 font-custom3">
          <span className="text-red">GO</span>DIET{' '}
        </h1>
        <button
          onClick={() => {
            window.location.href = '/services';
          }}
          className="text-white font-bold uppercase text-sm outline-none focus:outline-none px-6 py-3 rounded shadow hover:shadow-lg  mr-1 mb-1 ease-linear transition-all duration-150">
          more
        </button>
      </div>
    </div>
  );
};

export default HomeHeader;
