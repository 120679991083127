const AppFeatures = [
  {
    id: 1,
    details: 'Full first-offline workout tracker application',
  },
  {
    id: 2,
    details: 'Submit your daily workout records',
  },
  {
    id: 3,
    details: 'Sumbit support Ticket ',
  },
  {
    id: 4,
    details: 'Submit ',
  },
  {
    id: 5,
    details: 'BMI Calculator',
  },
  {
    id: 6,
    details: 'Workout updates monthly',
  },
  {
    id: 7,
    details: 'Workout substitution with your own exercises',
  },
  {
    id: 8,
    details: 'Workout at home or at the gym',
  },
  {
    id: 9,
    details: 'Access to the private community',
  },
];

export default AppFeatures;
