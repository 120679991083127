import { createContext, useState } from 'react';
import Step from './steps';
import Stepper from './Stepper';
import { Helmet } from 'react-helmet';
export const FormContext = createContext();

function FormIndex() {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formData, setFormData] = useState({});

  return (
    <FormContext.Provider
      value={{ activeStepIndex, setActiveStepIndex, formData, setFormData }}>
      <Helmet title="Nowruz registration form" />

      <div className="w-screen h-screen flex flex-col items-center justify-start bg-main">
        <Stepper />
        <Step />
      </div>
    </FormContext.Provider>
  );
}

export default FormIndex;
