import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';

export default function Services() {
  const location = useLocation();
  const { data } = location.state;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GoDiet - {data.name}</title>
        <link
          rel="canonical"
          href={`https://fitlinez.com/service/${data.id}`}
        />
      </Helmet>
      <div className="bg-main">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-y-16 gap-x-8 py-24 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
          <div>
            <h2 className="text-3xl font-bold  tracking-tight text-gray-900 sm:text-4xl">
              {data.name}
            </h2>
            <p className="mt-4 text-white ">{data.description}</p>

            <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
              {data.features?.map((feature) => (
                <div
                  key={feature.name}
                  className="border-t border-gray-200 pt-4">
                  {/* <dt className="font-medium text-gray-900">{feature.name}</dt> */}
                  <dd className="mt-2 text-sm text-white ">
                    {feature.details}
                  </dd>
                </div>
              ))}
            </dl>
            <dd className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8 text-white"></dd>
            <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
              {data.active ? (
                <Link
                  to="/checkout"
                  state={{
                    data: data?.product_id,
                    price: data.price,
                    name: data.name,
                  }}
                  className="bg-main text-white w-full font-bold uppercase text-sm outline-none focus:outline-none px-6 py-3 rounded shadow hover:shadow-lg  mr-1 mb-1 ease-linear border border-persian-indigo-700 transition-all duration-150">
                  {data.price} {data.sign} Start Now
                </Link>
              ) : (
                <div className="bg-main text-white w-full font-bold uppercase text-sm outline-none focus:outline-none px-6 py-3 rounded shadow hover:shadow-lg  mr-1 mb-1 ease-linear border border-persian-indigo-700 transition-all duration-150">
                  ثبت نام بزودی شروع می گردد{' '}
                </div>
              )}
            </dl>
          </div>
          <div>
            <img
              src={data.imageSrc}
              alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
              className="rounded-lg w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </>
  );
}
