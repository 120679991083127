import React from 'react';
import './index.css';

function FitlinezPrivacyPolicy() {
  return (
    <div className="bg-main app rtl-grid text-justify">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-extrabold text-gray-900 mb-8">
          GoDiet Privacy Policy
        </h1>
        <p className="mb-4 text-justify dire rtl-grid">
          At GoDiet, we value the privacy of our users and strive to protect
          their rights under GDPR. We assure you that any information we receive
          from you is handled in accordance with the GDPR and with utmost
          respect for your privacy.
        </p>
        <p className="mb-4">
          Fitlinez provides a healthy lifestyle coaching service to help improve
          your fitness and health. In order to provide you with the best
          possible service and experience, we collect information such as your
          name, email address, and other relevant information related to your
          fitness, health, and personal preferences. This information is used
          solely to improve our services and will not be shared with any third
          party.
        </p>
        <p className="mb-4 rtl-grid">
          We also have a fitness application that you can use to track your
          workouts and progress. To use the app, you may need to provide us with
          information such as your workout activities, lifestyle habits, and
          other health-related information. However, this information is also
          solely used to improve our services and will not be shared with any
          third party.
        </p>
        <p className="mb-4 rtl-grid">
          At Fitlinez, we take the security of your information very seriously.
          To this end, we use advanced security technologies to protect your
          data and ensure that your privacy is maintained. We also provide you
          with the option to delete your data or opt-out of our services at any
          time.
        </p>
        <p>
          By using our services, you agree to the terms of this privacy policy
          and the collection, use, and disclosure of your information in
          accordance with GDPR. If you have any questions or concerns about our
          privacy practices, please contact us.
        </p>
      </div>
    </div>
  );
}

export default FitlinezPrivacyPolicy;
