import axios from 'axios';
import { useEffect, useState } from 'react';
import activities from '../../data/activities.js';
import Modal from '../../component/Modal.js';
import { Helmet } from 'react-helmet';
const Calculator = () => {
  const [userData, setUserData] = useState({});
  const lengthOfData = Object.keys(userData).length;
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({}); //state to store the props to be passed to the modal
  const inputStyle =
    'mt-2 block w-full rounded-md border-0 py-1.5 text-main shadow-sm ring-1 ring-inset ring-main placeholder:text-main focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6';
  const selectStyle =
    'mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-main shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6';
  const lableStyle = 'block text-sm font-medium leading-6 text-main';
  const divStyle = 'col-span-6 sm:col-span-3 lg:col-span-3';
  const buttonStyle =
    'bg-main hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mb-10';
  const buttonDisableStyle =
    'bg-gray-500  text-gray-300 py-2 px-4 border border-blue-700 rounded mb-10';
  const [isEnabled, setIsEnabled] = useState(false);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  useEffect(() => {
    if (lengthOfData >= 8 && userData.email !== '' && userData.name !== '') {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [lengthOfData, userData]);

  const handleSubmitUserInfo = (e) => {
    e.preventDefault();
    console.log(userData);

    axios
      .post(`${process.env.REACT_APP_SERVER_PAYMENT}/cta`, userData)
      .then((res) => {
        setModalProps({
          message: 'Result will be send to ' + userData.email,
        }); //set the props to be passed to the modal
        setShowModal(true);
        console.log(res.data);
      });
  };

  return (
    <div class="mt-10 sm:mt-0">
      <Helmet
        title="Calculator | Calorie Calculator"
        meta={[
          {
            name: 'description',
            content: 'Calculator | Calorie Calculator',
          },
        ]}
      />

      {showModal && (
        <Modal {...modalProps} onClose={() => setShowModal(false)} />
      )}
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-base font-semibold leading-6 text-main mt-20">
              Calculator | Calorie Calculator{' '}
            </h3>
            <p class="mt-1 text-sm text-main">
              Please fill the form to calculate your daily calorie needs.
            </p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <form>
            <div class="overflow-hidden shadow sm:rounded-md">
              <div class="bg-white px-4 py-5 sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  <div class={divStyle}>
                    <label for="first-name" class={lableStyle}>
                      Age
                    </label>
                    <input
                      required
                      onChange={handleInput}
                      type="number"
                      name="age"
                      id="first-name"
                      autocomplete="given-name"
                      class={inputStyle}
                    />
                  </div>
                  <div class={divStyle}>
                    <label class={lableStyle}>Weight</label>
                    <input
                      required
                      onChange={handleInput}
                      type="number"
                      name="weight"
                      id="first-name"
                      autocomplete="given-name"
                      class={inputStyle}
                    />
                  </div>

                  <div class={divStyle}>
                    <label class={lableStyle}>Height</label>
                    <input
                      required
                      onChange={handleInput}
                      type="number"
                      name="height"
                      id="last-name"
                      autocomplete="family-name"
                      class={inputStyle}
                    />
                  </div>

                  <div class={divStyle}>
                    <label class={lableStyle}>Gender</label>
                    <select
                      required
                      onChange={handleInput}
                      id="gender"
                      name="gender"
                      autocomplete="country-name"
                      class={selectStyle}>
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                    </select>
                  </div>
                  <div class={divStyle}>
                    <label class={lableStyle}>Activity level </label>
                    <select
                      required
                      onChange={handleInput}
                      id="activity"
                      name="activity"
                      autocomplete="country-name"
                      class={selectStyle}>
                      {activities.map((activity) => (
                        <option value={activity.name}>{activity.alias}</option>
                      ))}
                    </select>
                  </div>

                  <div class={divStyle}>
                    <label class={lableStyle}> Waist</label>
                    <input
                      required
                      onChange={handleInput}
                      type="number"
                      name="waist"
                      id="waist"
                      autocomplete="street-address"
                      class={inputStyle}
                    />
                  </div>

                  <div class={divStyle}>
                    <label class={lableStyle}>Neck </label>
                    <input
                      required
                      onChange={handleInput}
                      type="number"
                      name="neck"
                      id="neck"
                      class={inputStyle}
                    />
                  </div>

                  <div class={divStyle}>
                    <label for="hip" class={lableStyle}>
                      Hip{' '}
                    </label>
                    <input
                      required
                      onChange={handleInput}
                      type="number"
                      name="hip"
                      id="hip"
                      autocomplete="address-level1"
                      class={inputStyle}
                    />
                  </div>

                  <div class={divStyle}>
                    <label class={lableStyle}>Name</label>
                    <input
                      required
                      onChange={handleInput}
                      type="text"
                      name="name"
                      id="name"
                      autocomplete="name"
                      class={inputStyle}
                    />
                  </div>
                  <div class={divStyle}>
                    <label class={lableStyle}> Email</label>
                    <input
                      required
                      onChange={handleInput}
                      type="email"
                      name="email"
                      id="email"
                      autocomplete="email"
                      class={inputStyle}
                    />
                  </div>
                </div>
              </div>

              <div class={divStyle}>
                <button
                  disabled={!isEnabled}
                  onClick={handleSubmitUserInfo}
                  type="submit"
                  className={isEnabled ? buttonStyle : buttonDisableStyle}>
                  See Result
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
