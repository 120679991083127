import { useEffect, useState } from 'react';
import axios from 'axios';
// import Modal from '../components/Modal';
// import Error from '../components/Error';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from 'react-simple-captcha';

const inputStyle =
  'border border-solid divide-gray-300 w-80 h-10 pl-2 my-1 text-main';
const errorStyle = 'text-xs text-red-700 mb-3';
const validation = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  message: Yup.string().min(2, 'Too Short!').required('Required'),
  captcha: Yup.string().required('Required'),
});

const Message = () => {
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    loadCaptchaEnginge(4);
  }, []);
  return (
    <div className="flex flex-col items-center">
      <Formik
        validationSchema={validation}
        initialValues={{
          name: '',
          email: '',
          message: '',
          captcha: '',
        }}
        onSubmit={async (values) => {
          setIsloading(true);
          if (validateCaptcha(values.captcha, false) === true) {
            axios
              .post(`${process.env.REACT_APP_SERVER_PAYMENT}/contact`, {
                values,
              })
              .then((res) => {
                // console.log(res);
                setIsloading(false);
                setShowModal(true);
              })
              .catch((err) => {
                // console.log(err);
                setIsloading(false);
                setShowError(true);
              });
          } else {
            setIsloading(false);
            alert('کد امنیتی اشتباه است');
          }
        }}>
        {({ errors, touched }) => (
          <Form className=" flex flex-col text-sm mx-2">
            <Field
              id="name"
              name="name"
              placeholder="نام"
              className={inputStyle}
            />
            {errors.userName && touched.userName ? (
              <div className={errorStyle}>{errors.userName}</div>
            ) : null}

            <Field
              id="email"
              name="email"
              placeholder="آدرس ایمیل"
              type="email"
              className={inputStyle}
            />
            {errors.userEmail && touched.userEmail ? (
              <div className={errorStyle}>{errors.userEmail}</div>
            ) : null}

            <Field
              id="message"
              name="message"
              placeholder="پیام"
              as="textarea"
              className="border border-solid  divide-gray-300 w-80 h-36 pl-2 pt-2 my-1 break-words"
            />
            {errors.userMessage && touched.userMessage ? (
              <div className={errorStyle}>{errors.userMessage}</div>
            ) : null}
            <div className="flex justify-center items-center">
              <LoadCanvasTemplate />
              <Field
                id="captcha"
                name="captcha"
                placeholder="کد امنیتی"
                className="border border-solid divide-gray-300 w-32 h-10 pl-2 my-1 text-main"
              />
              <button
                type="submit"
                className="rounded-full text-main border border-solid border-persian-indigo-700  w-32 px-4 py-2 mt-8 active:scale-90 active:bg-persian-indigo-50 shadow hover:shadow-lg ">
                {isloading ? 'در حال ارسال...' : 'ارسال پیام'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {/* <div>{showModal ? <Modal /> : null}</div>
        <div>{showError ? <Error /> : null}</div> */}
    </div>
  );
};

export default Message;
