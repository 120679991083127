const callouts = [
  {
    id: 1,
    active: true,
    name: 'Executive lifestyle coaching',
    product_id: 'price_1MqZzJAB6MVrXxqzsXY6cSv3',
    description: 'Executive lifestyle coaching inside Fitlinez App',
    price: '189.99',
    paymantUrl: 'https://buy.stripe.com/fZebJYanGcVE9IQbJ6',
    currency: 'EUR',
    sign: '€',
    period: 'Monthly',
    features: [
      {
        id: 1,
        details: '4 sessions per month',
      },
      {
        id: 2,
        details: 'Exclusive lifestyle coaching',
      },
      {
        id: 3,
        details: 'full access to the workout application',
      },
      {
        id: 4,
        details: 'Submit your workout and nutrition plan',
      },
      {
        id: 5,
        details: 'Access to the private community',
      },
      {
        id: 6,
        details: ' ',
      },
      {
        id: 7,
        details: 'ability to change your plan',
      },
      {
        id: 8,
        details: 'Home and Gym workout plans',
      },
    ],
    imageSrc:
      'https://tailwindui.com/img/ecommerce-images/home-page-02-edition-01.jpg',
    imageAlt:
      'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
    href: '#',
  },
  {
    id: 2,
    active: true,
    name: 'Fitlinez Workout App',
    //product_id: 'price_1MqZMWAB6MVrXxqzrkmsdWDC',
    product_id: 'price_1MEraBAB6MVrXxqz5m15A6SN',
    descriptio: 'Fitlinez Workout App. iOS and Android',
    features: [
      {
        id: 1,
        details: 'fully Offline first workout application',
      },
      {
        id: 2,
        details: 'Save your records on the app',
      },
      {
        id: 3,
        details: 'Submit support ticket and get a response within 24 hours.',
      },
      {
        id: 4,
        details: 'Track your progress and see your results',
      },
      {
        id: 5,
        details: 'BMI Calculator ',
      },
      {
        id: 6,
        details: 'Monthly workout updates',
      },
      {
        id: 7,
        details: 'Replace exercises with your own',
      },
      {
        id: 8,
        details: 'Workout at home or at the gym',
      },
      {
        id: 9,
        details: 'Access to the private community',
      },
    ],
    price: '9.99',

    currency: 'EUR',
    sign: '€',
    period: 'ماهیانه',
    imageSrc: require('../img/app.png'),

    imageAlt:
      'Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.',
    href: '#',
  },
];

export default callouts;
