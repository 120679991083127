import { Helmet } from 'react-helmet';
import Apple from '../../img/appstore.webp';
import Google from '../../img/google.webp';
import HomeOverView from '../home/productOverView';

const Download = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <Helmet
        title="Download | Fitlinez  "
        meta={[
          {
            name: 'description',
            content: 'دانلود اپلیکیشن فیتلاینز',
          },
          {
            name: 'keywords',
            content: 'دانلود اپلیکیشن فیتلاینز',
          },
        ]}
      />
      <HomeOverView />
      {/* <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8 text-main">
          دانلود اپلیکیشن فیتلاینز
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-10">
          <div className="flex items-center h-full mx-auto">
            <a href="https://play.google.com/store/apps/details?id=com.fitlinez.eu&hl=en">
              <h3 className="text-center text-main mb-5">دانلود از</h3>
              <img
                className="object-fit "
                src={Google}
                alt="Fitlinez google Play Store"
              />
            </a>
          </div>

          <div className="flex items-center h-full mx-auto">
            <a href="https://apps.apple.com/app/fitlinez/id6443489365">
              <h3 className="text-center text-main mb-5">دانلود از</h3>
              <img
                className="object-fit"
                src={Apple}
                alt="Fitlinez App Store"
              />
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Download;
