import { Helmet } from 'react-helmet';
import FormIndex from '../../component/caculator';
import HomeHeader from './homeHeader';
import HomeStats from './homeStats';
import HomeOverView from './productOverView';
import Services from './Services';

const Home = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GoDiet OU</title>
        <link rel="canonical" href="https://godiet.eu" />
      </Helmet>
      <HomeHeader />

      <Services />
      <HomeOverView />
    </div>
  );
};

export default Home;
