import './App.css';
import Navbars from './component/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/home';
import FooterApp from './component/footer';
import PaymentIndex from './pages/payment';
import Download from './pages/download';
import Calculator from './pages/tools';
import Contact from './pages/contact';
import Services from './pages/packages/package';
import Checkout from './pages/checkout';
import FitlinezPrivacyPolicy from './pages/privacy';
import NotFound from './pages/404';
import PaymentSuccess from './pages/checkout/thanks';
import PaymentUnSuccess from './pages/checkout/cancel';

function App() {
  return (
    <div className="App rtl">
      <Router>
        <Navbars />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricelist" element={<PaymentIndex />} />
          <Route path="/application" element={<Download />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<PaymentIndex />} />

          <Route path="/service/:id" element={<Services />} />
          <Route path="/privacy-policy" element={<FitlinezPrivacyPolicy />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/successful-payment" element={<PaymentSuccess />} />
          <Route path="/unsuccessful-payment" element={<PaymentUnSuccess />} />
        </Routes>
        <FooterApp />
      </Router>
    </div>
  );
}

export default App;
